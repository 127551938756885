import { useCallback, useEffect, useState } from "react";
import { SendMessage } from "../../protocol";

interface LogInProps {
  onSendMessage(message: SendMessage): void;
  defaultClientAccessId: string;
}

export const LogIn: React.FC<LogInProps> = (props) => {
  const { onSendMessage } = props;

  const [clientAccessId, setClientAccessId] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    setClientAccessId(props.defaultClientAccessId);
  }, [props.defaultClientAccessId]);

  const handleLogin: React.FormEventHandler = useCallback(
    (e) => {
      e.stopPropagation();
      e.preventDefault();

      onSendMessage({
        message: "log-in",
        clientAccessId,
        emailAddress,
        password,
      });
    },
    [onSendMessage, clientAccessId, emailAddress, password]
  );

  return (
    <details>
      <summary>Log In</summary>
      <p>
        <strong>Note:</strong> In this demo app, the email and password will be
        logged in your browser's Web Inspector console and under "Show Message
        History"
      </p>
      <form onSubmit={handleLogin}>
        <label>
          <p>Client Access ID</p>
          <input
            type="text"
            value={clientAccessId}
            onChange={(e) => setClientAccessId(e.target.value)}
          />
        </label>
        <label>
          <p>Email address</p>
          <input
            type="email"
            value={emailAddress}
            onChange={(e) => setEmailAddress(e.target.value)}
          />
        </label>
        <label>
          <p>Password</p>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </label>
        <button type="submit">Sign In</button>
      </form>
    </details>
  );
};
